<template>
    <div class="bodyfull" v-loading.fullscreen.lock='loadingfull' element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.5)">
        <div class="top">
            <ShopsAndSites
                v-if="tress.length>0"
                placeholder="请选择店铺"
                style="margin-right:10px;"
                :list='tress'
                @getShopId='getID'>
            </ShopsAndSites>
            <span v-if="tress.length<=0" style="margin-right:10px">无店铺数据，请刷新页面</span>
            <!-- <el-input style="width:280px;margin-right:10px" placeholder="输入搜索内容">
            </el-input>
            <el-button class="el-icon-search">搜索</el-button> -->
        </div>
        <div class="content">
            <div class="left" v-loading="leftLoading">
                <p class="time">
                    同步时间：{{syncTime}}
                    <span v-if="!loading.syncIcon" @click="mailSync" style="margin-left:15px;margin-bottom:10px" class="el-icon-refresh"></span>
                    <span v-if="loading.syncIcon" style="margin-left:15px;margin-bottom:10px" class="el-icon-loading"></span>
                    <el-button @click="newMail" class="el-icon-edit"  style="width:100%;color:#006EFF;border:1px solid #99C5FF">新建邮件</el-button>
                </p>
                <div class="navLeft">
                    <mailLeftNav 
                    @click.native="getMail(item)"
                    :style="{margin:'0px 0 0',background:item.id==leftColor?'#E4E7ED':''}"
                    v-for="(item,index) in leftMenu.list" 
                    :info='item'
                    @commonControl='commonControl'
                    :key="index"></mailLeftNav>

                    <mailLeftNav 
                    :info="{name:'常用文件夹',type:'control'}"
                    :key="index" ></mailLeftNav>
                    <el-tree
                    style="margin:0px 0 6px 0;padding-left:10px"
                    :data="leftMenu.commonFolder"
                    node-key="id"
                    :accordion='true'
                    :expand-on-click-node="true">
                    <span class="custom-tree-node" slot-scope="{data }">
                        <mailLeftNav 
                        @click.native='getMail(data)'
                        :style="{background:data.id==leftColor?'#E4E7ED':'',borderRadius:'8px'}"
                        :info="data"
                        :key="index"
                        @setReaded='setAllIsRead'
                        ></mailLeftNav>
                    </span>
                    </el-tree>


                    <mailLeftNav 
                    :info="{name:'邮箱列表',type:'control'}"
                     ></mailLeftNav>
                     <el-tree
                    style="margin:0px 0 6px 0;padding-left:10px"
                    :data="leftMenu.mailList"
                    node-key="id"
                    :accordion='true'
                    :expand-on-click-node="true">
                    <span class="custom-tree-node" slot-scope="{data }">
                        <mailLeftNav 
                        @click.native='getMail(data)'
                        :style="{background:data.id==leftColor?'#E4E7ED':'',borderRadius:'8px'}"
                        :info="data"
                        :key="index" 
                        @setReaded='setAllIsRead'></mailLeftNav>
                    </span>
                    </el-tree>

                    <mailLeftNav 
                    :info="{name:'标签',type:'control',plus:true}"
                    @showTagBox='TagBox'
                     ></mailLeftNav>
                     <div>
                         <div>
                             <div @click="getMailByTag(item)" class="itemTag" :style="{paddingLeft:'15px',height:'25px',lineHeight:'25px',background:item.id==leftColor?'#E4E7ED':'',cursor:'pointer'}" 
                            v-for="(item,index) in leftMenu.tagList" 
                            :key="index">
                                <div :style="{background:item.color}" class="tagColor"></div>
                                <span style="margin-left:7px">{{item.name}}{{item.size==0?'':'('+item.size+')'}}</span>

                                <span style="float:right;">
                                    <el-dropdown placement='bottom'>
                                    <span class="iconfont icon-gengduo tagMore"></span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item @click.native="setAllIsRead(item,'all')">全部已读</el-dropdown-item>
                                        <el-dropdown-item @click.native="removeTag(item.labelId)">删除标签</el-dropdown-item>
                                    </el-dropdown-menu>
                                    </el-dropdown>
                                </span>
                            </div>
                         </div>
                     </div>
                     <div class="colorBox" v-if="tag.tagShow">
                        <el-color-picker 
                        v-model="tag.tagColor"
                        :predefine="tag.predefineColors"></el-color-picker>
                        <el-input placeholder="标签名称" v-model="tag.tagName"  style="width:140px"></el-input>
                        <span class="el-icon-check" @click="addTag"></span>
                        <span class="el-icon-close" @click="TagBox"></span>
                    </div>
                     <!-- 标签 -->
                </div>
            </div>
            <div class="middle" v-loading='middleLoading'>
                <div v-if="mailList.length==0" style="width:100%;height:100%;color:#999999;font-size:14px;line-height:400px;text-align:center">
                    暂无内容
                </div>
                <div v-if="mailTotal!=0">
                    <!-- 全选 -->
                    <div class="selectAll">
                        <el-checkbox v-model="checkAll" label="1" @change="selectAll">{{
                            }}</el-checkbox>
                        <el-dropdown trigger="click" placement='bottom' :hide-on-click='true'>
                            <span style="margin-left:4px;cursor:pointer;margin-left:-2px" >{{mailTitle}} <i class="el-icon-caret-bottom"></i></span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item 
                                @click.native="sortMail(item,index)"
                                v-for="(item,index) in sortColumn"
                                :key="item.prop">
                                <span style="display:inline-block;width:10px;margin:0 4px 0 -4px;color:#006EFF" :class="item.icon?'iconfont icon-duihao1':''"></span>
                                {{item.value}}
                                </el-dropdown-item>
                                <el-dropdown-item 
                                @click.native="sort(item,index)"
                                :divided='item.divided'
                                v-for="(item,index) in sortList"
                                :key="item.prop">
                                <span style="display:inline-block;width:10px;margin:0 4px 0 -4px;color:#006EFF" :class="item.icon?'iconfont icon-duihao1':''"></span>
                                {{item.name}}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="mailContent">
                        <div  class='item'
                        :style="{background:item.id==mailListColor?'#E4E7ED':''}"
                        @click.stop="getMailContent(item,index)"
                        v-for="(item,index) in mailList"
                        :key="item.id">
                            <el-checkbox v-model="checkList" :label="item.id" @change="select">{{
                                }}</el-checkbox>
                            <span style="margin-left:0px">
                                <span style="margin-left:0px;margin-right:5px" class="not" v-if="!item.read"></span>
                                <span @click. style="max-width:195px" class="on-line">{{item.name}}</span>
                            </span>
                            <span v-if="!item.isNeedReply"  class="noneed">无需回复</span>
                            <span style="float:right;margin-top:3px">
                                <el-dropdown :hide-on-click='false' trigger="click" placement='bottom'>
                                    <span class="iconfont icon-gengduo more" style="z-index:9999999" @click.stop=''></span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item @click.native.self="setAllIsRead({...item,onRead:'read'})" v-if="!item.read&&item.boxType!='RakuTenMail'">标记已读</el-dropdown-item>
                                        <el-dropdown-item @click.native="setAllIsRead({...item,onRead:'unread'})" v-if="item.read&&item.boxType!='RakuTenMail'">标记未读</el-dropdown-item>
                                        <el-dropdown-item @click.native="setCompleted({id:item.id,shopId:item.shopId,type:'complete'})" v-if="item.boxType=='RakuTenMail'">标记已完成对话</el-dropdown-item>
                                        <el-dropdown-item @click.native="setCompleted({id:item.id,shopId:item.shopId,type:'incomplete'})" v-if="item.boxType=='RakuTenMail'">标记未完成对话</el-dropdown-item>
                                        <el-dropdown-item>
                                            <el-popover
                                            placement="right"
                                            width="200"
                                            trigger="hover">
                                                <div>
                                                    <div class="mailListTag" style="padding-left:0">
                                                        <div>
                                                            <el-input @input="searchTag" v-model="searchTaginput" class="addTag" placeholder="搜索内容"></el-input>
                                                        </div>
                                                        <span @click="makeTag({labelId:i.labelId,id:item.id})" style="margin-bottom:4px" :style="{border:`1px solid ${i.color}`,color:i.color}" v-for="i in leftMenu.selectTagList" :key="i.id">{{i.name}}</span>
                                                    </div>
                                                </div>
                                                <p v-if="item.boxType=='INBOX'" slot="reference" style="display:flex;justify-content:space-between;align-items:center">
                                                    <span>打标签</span>
                                                    <i class="el-icon-arrow-right" style="margin-left:10px"></i>
                                                </p>
                                            </el-popover>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </span>
                            
                            <span v-if="item.containsFile" style="color:orange;float:right;margin-right:3px;margin-top:3px" class="el-icon-paperclip"></span>
                            <div class="item-text" :style="{justifyContent:getMailInfo.boxType=='RakuTenMail'?'flex-start':'space-between'}">
                                <span v-if="getMailInfo.boxType=='RakuTenMail'" class="isCompleted" :style="{border:item.isCompleted?'1px solid #99C5FF':'1px solid red',background:item.isCompleted?'#99C5FF':'red',color:'white'}">{{item.isCompleted?'已完成':'未完成对话'}}</span>
                                <!-- <p :style="{fontSize:'14px',paddingLeft:getMailInfo.boxType=='RakuTenMail'?'7px':'20px',maxWidth:getMailInfo.boxType=='RakuTenMail'?'210px':'290px'}"}">{{item.subject}}</p> -->
                                <p :style="{fontSize:'14px',paddingLeft:getMailInfo.boxType=='RakuTenMail'?'7px':'20px',maxWidth:getMailInfo.boxType=='RakuTenMail'?'210px':'290px'}">{{item.subject}}</p>
                                <span v-if="(!item.isReply&&!getMailType)||(item.isReply&&getMailType)?false:true" style="float:right;position:absolute;right:0;top:10px;font-size:12px">
                                    {{item.date}}
                                </span>
                                <span v-if="!item.isReply&&!item.outToday&&!getMailType" class="outTime">超时未回复</span>
                                <span v-if="!item.isReply&&item.outToday&&!getMailType" class="iconfont icon-daojishi" style="color:red;font-size:12px">&nbsp;{{item.outTime}}</span>
                            </div>
                            <div class="mailListTag">
                                <span :style="{border:`1px solid ${i.color}`,color:i.color}" v-for="i in item.labelList" :key="i.id">{{i.value}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="pagination">
                        <el-pagination
                        @current-change="mailListCurrent"
                        :current-page.sync="getMailInfo.current"
                        pager-count='3'
                        :page-size="getMailInfo.pageSize"
                        :small='true'
                        layout="total, prev, pager, next"
                        background
                        :total="mailTotal">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <div class="chat" v-loading='chatLoading'>
                <div v-if="!chatBoxInfo.sender" style="text-align:center">
                    <img src="../../assets/img/pic-mail-01.3e065957.png" alt="" width="600px">
                    <p style="color:#999999">请选择邮件</p>
                </div>
                <div class="chat" v-show="chatBoxInfo.sender">
                    <div class="operate">
                        <template v-if="chatBoxInfo.boxType=='Drafts'">
                            <div class="el-icon-edit" @click="getDraftInite">编辑</div>
                            <div class="el-icon-delete" @click="delDraft">删除</div>
                        </template>
                        <template v-if="chatBoxInfo.boxType!='Drafts'">
                            <template v-if="chatBoxInfo.sender&&!mailType">
                            <div class="" @click="replied('noNeedReply')"> 标记为无需回复</div>
                            <div class="" @click="replied('repliedOnPlatform')"> 已在平台回复</div>
                            <div v-if="chatBoxInfo.boxType!='RakuTenMail'" class="iconfont icon-youjian" @click="setAllIsRead({onRead:'unread',id:chatBoxInfo.id})"> 标记未读</div>
                            </template>
                            <template v-if="chatBoxInfo.sender&&mailType&&checkList.length==0">
                                <div class="iconfont icon-yihuifu" @click="replyMail('Re')"> 回复</div>
                                <div class="iconfont icon-reply-full" @click="replyMail('ReAll')"> 回复全部</div>
                                <div class="iconfont icon-hanhan-01-01" @click="transpond('Fw')"> 转发</div>
                            </template>
                            <template v-if="chatBoxInfo.sender&&mailType">
                                    <el-popover
                                    v-if="chatBoxInfo.boxType=='INBOX'"
                                    placement="bottom"
                                    width="200"
                                    trigger="hover">
                                        <div>
                                            <div class="mailListTag" style="padding-left:0">
                                                <div>
                                                    <el-input @input="searchTag" v-model="searchTaginput" class="addTag" placeholder="搜索内容"></el-input>
                                                </div>
                                                <span @click="makeTag({labelId:i.labelId,id:chatBoxInfo.id})" style="margin-bottom:4px" :style="{border:`1px solid ${i.color}`,color:i.color}" v-for="i in leftMenu.selectTagList" :key="i.id">{{i.name}}</span>
                                            </div>
                                        </div>
                                        <div slot="reference" style="display:flex;justify-content:space-between;align-items:center">
                                            <div class="iconfont icon-tuding" style="padding:0;margin-right:0"> 打标签 <span class="el-icon-caret-bottom"></span></div>
                                        </div>
                                    </el-popover>
                                <el-dropdown trigger="click">
                                    <div v-if="chatBoxInfo.boxType!='RakuTenMail'" class="iconfont icon-caidan" style="padding:0;margin-right:0"> 更多 <span class="el-icon-caret-bottom"></span></div>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item @click.native="setAllIsRead({onRead:'read',id:chatBoxInfo.id})">标记已读</el-dropdown-item>
                                        <el-dropdown-item @click.native="setAllIsRead({onRead:'unread',id:chatBoxInfo.id})">标记未读</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </template>
                        <span v-if="checkList.length!=0" style="margin-left:140px;font-weight:800;color:red">已选择 {{checkList.length}} 封邮件</span>
                    </div>
                    <div style="height:calc(100% - 40px);overflow:auto" class="mailText">
                        <div style="" v-show="mailType">
                            <el-collapse class="dd" @change="collapseChange" v-model="activeName">
                                <el-collapse-item name="1">
                                    <template slot="title">
                                        <div style="display:flex;align-items:center;justify-content:space-between">
                                            <div class="biaoti">
                                                {{chatBoxInfo.subject}}
                                            </div>
                                            <div v-show="!shrink" style="color:#529DFF">展开详情</div>
                                            <div v-show="shrink" style="color:#529DFF">隐藏详情</div>
                                        </div>
                                    </template>
                                    <p class="coc" style="padding-left:20px;font-size:12px">
                                        <span style="font-size:12px">发件人：</span><span @click="copy" id="copy" style="color:black;font-size:12px">{{chatBoxInfo.sender}}</span>
                                    </p>
                                    <p class="on-line" style="padding-left:20px;font-size:12px;max-width:500px;overflow:hidden">
                                        <span style="font-size:12px">收件人：{{chatBoxInfo.mailTo}}</span>
                                    </p><br>
                                    <p v-if="chatBoxInfo.cc?true:false" class="on-line" style="padding-left:20px;font-size:12px;max-width:500px;overflow:hidden">
                                        <span style="font-size:12px">抄送人：{{chatBoxInfo.cc}}</span>
                                    </p>
                                    <p style="padding-left:20px;font-size:12px">
                                        <span style="font-size:12px">时间：</span><span style="font-size:12px">{{chatBoxInfo.date}}</span>
                                    </p>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div v-if="!mailType" style="background:white;height:60px;position:sticky;top:0;left:0;right:0;z-index:999">
                            <p style="border-top:1px solid #E4E7ED;">
                                <span style="font-size:17px;font-weight:700;color:black;margin-right:14px;margin-left:10px;line-height:34px">{{chatBoxInfo.senderNick}}</span>
                                <span>{{chatBoxInfo.sender}}</span>
                            </p>
                            <p style="border-bottom:1px solid #E4E7ED;padding-left:10px;padding-bottom:10px">{{chatBoxInfo.subject}}</p>
                        </div>
                        <!-- 聊天内容区域 -->
                        <div style="flex:1" v-show="mailType">
                            <div style='padding:10px 10px 10px 15px;overflow:auto;line-height:25px' >
                                <div style="width:100%;height:100%">
                                    <iframe id="myiframe" style="width:100%" frameborder="0" :srcdoc="chatBoxInfo.content" scrolling="no" width="100%"></iframe>
                                </div>
                                <div v-if="chatBoxInfo.fileList.length!=0">
                                    <p style="margin:20px 10px 10px 0">
                                        <span style="color:gray;font-weight:600">附件({{chatBoxInfo.fileList.length}})</span>
                                        <!-- <span @click="downAll" style="margin-left:10px;color:#529DFF;cursor:pointer">下载全部</span> -->
                                    </p>
                                    <div>
                                        <div
                                        @click="down(item)"
                                        v-for="item in chatBoxInfo.fileList"
                                        :key="item.fileUrl"
                                        style="display:inline-block;width:130px;background:#FDFDFD;text-align:center;border:1px solid #EBEEF5;cursor:pointer;margin-right:10px;padding-top:6px">
                                            <img v-if="item.icoType=='pdf'" src="../../assets/img/pdf.png" alt="" width="30px">
                                            <img v-if="item.icoType=='xls'||item.icoType=='xlsx'" src="../../assets/img/excel.png" alt="" width="30px">
                                            <img v-if="item.icoType=='doc'||item.icoType=='docx'" src="../../assets/img/word.png" alt="" width="30px">
                                            <img v-if="item.icoType=='jpg'||item.icoType=='png'||item.icoType=='JPEG'||item.icoType=='bmp'||item.icoType=='gif'" :src="item.fileUrl" alt="" width="30px">
                                            <p class="on-line"  style="margin-top:4px;width:125px">{{item.fileName}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 站内信聊天内容 -->
                        <div id="chatb" v-if="!mailType" style="background:white;position:absolute;top:60px;left:0;right:0;bottom:160px;margin-top:40px;overflow:auto">
                            <div class="msgBox" v-for="item in chatBoxInfo.contentList" :key='item.id'>
                                <div :style="{float:item.which=='left'?'left':'right'}">
                                    <span>{{item.date}}</span>
                                    <span v-if="item.which=='left'&&item.replied">
                                        <el-tooltip class="item" effect="dark" content="已在平台回复" placement="top-start">
                                            <span class="iconfont icon-duihao replyIcon"></span>
                                        </el-tooltip>
                                    </span>
                                    <div :style="{background:item.which=='left'?'#EFF6FF':'#F7F8FA',wordWarp:'break-word',wordBreak:'break-all'}" v-dompurify-html="item.content">
                                    </div>
                                    <p>
                                        <span @click="down(item)" style="height:24px;line-height:20px;border-radius:7px;padding:0;margin-top:3px;cursor:pointer" class="file" v-for="(item,index) in item.fileResList" :key="index">
                                            <span style="color:orange;margin: 0 5px 0 9px" class="el-icon-paperclip"></span>
                                            <span style="width:130px;height:24px;line-height:24px;" class="file on-line">{{item.fileName}}</span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- 输入区 -->
                        <div v-if="!mailType" class="chatInput" :style="{height:'160px'}">
                            <p style="padding:9px">
                                <span class="tempspan">
                            <el-popover
                            placement="top-start"
                            width="500"
                            close-delay='0'
                            @after-leave="nameeditHide"
                            ref="popover"
                            trigger="click">
                            <div class="temp" v-loading='loading.tagLoading'>
                               <div class="muban">
                                   <el-input @input.native="selectTag" v-model="tagKeyword" placeholder="搜索模板名称"></el-input>
                                   <p @mouseenter="enterTemplate(item)" @mouseleave="leaveTemplate" @click="matchProductInfo(item)" v-for="item in templateList" :key="item.id">
                                       <span class="on-line" style="width:120px;cursor:pointer">{{item.name}}</span>
                                    </p>
                                    <el-button @click="$router.push('/messageTemplate')" style="width:150px;border-top:1px solid #E4E7ED;position:absolute;left:0;bottom:0" type="text">添加模板</el-button>
                               </div>
                               <div>
                                   <p style="padding-left:15px">模板名称: {{templateName?templateName:'请选择模板'}}</p>
                                    <div v-dompurify-html="templateContent" style="white-space:pre-line;margin-top:10px;font-size:14px;color:#999999;line-height:25px;padding-left:12px" v-show="templateName">
                                    </div>
                                    <div style="text-align:center;margin-top:100px;font-size:14px;color:#999999" v-show="!templateName">
                                        请选择具体模板
                                    </div>
                               </div>
                            </div>
                            <span slot="reference">
                                <span  @click="tagListPage">
                                    选择模板
                                    <i class="el-icon-caret-bottom c"></i>
                                </span>
                            </span>
                            </el-popover>
                        </span>
                                <!-- <span style="margin-right:10px">添加模板 <i class="el-icon-caret-bottom"></i></span> -->
                                <span style="margin-right:10px;color:#EBEEF5">|</span>
                                <el-upload
                                style="display:inline-block"
                                class="upload-demo"
                                :action="action"
                                :headers="mytoken"
                                :on-progress='uploadProgressStation'
                                :on-success='uploadSuccessStation'
                                :on-error='uploadError'
                                multiple
                                :limit="5"
                                :show-file-list='false'
                                :file-list="fileListStation">
                                <span style="color:black;font-size:13px"><i style="font-size:13px" class="iconfont icon-shangchuan"></i>上传附件
                                    <el-tooltip placement="top">
                                    <div slot="content">(最大10M)您可以上传文本文件、PDF、Word及jpg/gif/png图片</div>
                                        <i class="iconfont icon-wenhao"></i>
                                    </el-tooltip>
                                    <i style="margin-left:4px" :class="progressLoading?'el-icon-loading':''"></i></span>
                                </el-upload>
                                <span style="height:20px;line-height:20px;border-radius:7px;padding-right:8px;margin-top:3px;cursor:pointer" class="file" v-for="(item,index) in fileListStation" :key="item">
                                    <span style="color:orange;margin: 0 5px 0 9px;" class="el-icon-paperclip"></span>
                                    <span style="width:80px;height:17px;line-height:17px;" class="file on-line">{{typeof item == 'object'?item.label:item}}</span>
                                    <i @click="delFileStation(index)" class="el-icon-close delFile"></i>
                                </span>
                                <!-- <span @click="shou" v-if="chatInputHeight!='130px'" style="float:right;font-size:12px;cursor:pointer" class="iconfont icon-quanping">收起</span> -->
                            </p>
                            <el-input type="textarea" rows="4" v-model="sendStationInfo.content" placeholder="请将你的内容限制在4000字内"></el-input>
                            <el-button @click="sendStationMail" class="butt" :disabled='!sendStationInfo.content&&this.fileListStation.length==0?true:false' :loading='loading.buttonLoading' size='mini' type="primary">发送</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!mailType" class="right">
                <el-tabs v-model="activeRight" @tab-click="handleClick">
                    <el-tab-pane label="订单详情" name="first"></el-tab-pane>
                    <el-tab-pane label="历史订单" name="second"></el-tab-pane>
                </el-tabs>
                <div class="order" v-if="orderMsgRes.orderNumber">
                    <div style="display:flex;justify-content:space-between;width:250px">
                        <p>订单号</p>
                        <div>
                            <el-dropdown trigger="click">
                            <el-link type='primary' class="el-dropdown-link">
                                操作<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-link>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>退款</el-dropdown-item>
                                <el-dropdown-item>创建多渠道订单</el-dropdown-item>
                            </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                    <!-- 订单号 -->
                    <p style="color:#40B8FF;font-size:12px;cursor: pointer;padding:4px 0" id="copy" @click="copy">{{orderMsgRes.orderNumber}}</p>
                    <div  class="type">
                        <div style="background:#FFF9C1;color:orange;font-size:12px;padding:2px 3px" v-if="orderMsgRes.orderStatus!=''">Shipped</div>
                        <div style="background:#EEF8E9;color:#5BBC2A;font-size:12px;padding:2px 3px" v-if="orderMsgRes.fulType!=''">FBA</div>
                    </div>
                    <p style="margin-top:25px">订购时间</p>
                    <p style="font-size:13px;line-height:25px">{{orderMsgRes.purchaseDate}}</p>
                    <p style="margin-top:25px">订单金额</p>
                    <p style="font-size:13px;line-height:25px">{{orderMsgRes.currency + orderMsgRes.orderTotal}}</p>
                    <p style="margin-top:25px">商品</p>
                    <div class="goods" v-for="(item,index) in orderMsgRes.orderItemMsgResList" :key="index">
                            <el-image 
                                @click="bigImg(item.imgUrl)"
                                style="width:80px;height:80px;margin-top:5px"
                                :src="item.imgUrl" 
                                z-index='999'
                                :preview-src-list="srcList">
                            </el-image>
                        <div style="flex:1;margin-left:20px;line-height:20px">
                            <el-tooltip placement="top">
                                <div style="width:200px" slot="content">{{item.title||'无title'}}</div>
                                <p 
                                style="
                                white-space: nowrap;
                                overflow:hidden;
                                width:150px;
                                cursor:pointer;
                                text-overflow: ellipsis;
                                ">{{item.title}}</p>
                            </el-tooltip>
                            
                            <p>ASIN:{{item.asin}}</p>
                            <p>MSKU:{{item.msku}}</p>
                            <p>SKU:{{item.sku}}</p>
                        </div>
                    </div>
                    <p style="margin-top:25px">备注：<el-link @click="tip=!tip" class="el-icon-edit"></el-link></p>
                    <el-input type="textarea" v-if="tip" style="margin-top:10px" placeholder="输入后点击空白处确定"></el-input>
                </div>
                <p v-else style="text-align:center;color:#999999;margin-top:20px">暂无数据</p>

            </div>
        </div>
        <el-dialog
            v-show="writeMailShow"
            :visible.sync="writeMail"
            style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)"
            :modal-append-to-body='false'
            :close-on-click-modal='false'
            :modal='false'
            :fullscreen='fullscreen'
            width="900px"
            :show-close='false'
            v-dialog
            :before-close="writeMailClose">
            <template slot="title">
                <div class="headerStyle">
                    <span @click="writeMailShow=false" style="display:inline-block;vertical-align:bottom" class="iconfont icon-zuixiaohua"></span>
                    <span @click="fullscreen=!fullscreen" class="iconfont icon-quanping"></span>
                    <span @click="queryDraft" class="el-icon-close"></span>
                </div>
            </template>
            <div style="min-height:600px;display:flex;flex-direction: column;">
                <el-form class="writeBox" style="background:#F7F7F7" ref="form" label-width="80px">
                    <div>
                        <div class="send">
                            <el-link @click="copyer=!copyer" v-if="!copyer" type='primary'>抄送</el-link>
                            <el-link @click="secreter=!secreter" v-if="!secreter" type='primary'>密送</el-link>
                        </div>
                        <el-form-item label="收件人">
                            <el-input onkeyup="this.value=this.value.replace(/[, ]/g,'')" v-model="sendInfo.toArr" placeholder="输入多个收件人用英文符号(;)分隔，最多5个"></el-input>
                        </el-form-item>
                    </div>
                    <div v-if="copyer">
                        <span @click="closeInput('抄送人')" class="send el-icon-delete"></span>
                        <el-form-item label="抄送人">
                            <el-input onkeyup="this.value=this.value.replace(/[, ]/g,'')" v-model="sendInfo.ccArr" placeholder="输入多个抄送人用英文符号(;)分隔，最多5个"></el-input>
                        </el-form-item>
                    </div>
                    <div v-if="secreter">
                        <span @click="closeInput('密送人')" class="send el-icon-delete"></span>
                        <el-form-item label="密送人">
                            <el-input onkeyup="this.value=this.value.replace(/[, ]/g,'')" v-model="sendInfo.bccArr" placeholder="输入多个密送人用英文符号(;)分隔，最多5个"></el-input>
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item label="主题">
                            <el-input v-model="sendInfo.subject" placeholder=""></el-input>
                        </el-form-item>
                    </div>
                    <div style="display:flex">
                        <el-form-item label-width="0">
                            <el-upload
                            style="display:inline-block"
                            class="upload-demo"
                            :action="actionOverall"
                            :headers="mytoken"
                            :on-progress='uploadProgress'
                            :on-success='uploadSuccess'
                            multiple
                            :limit="5"
                            :show-file-list='false'
                            :file-list="fileList">
                            <span style="margin-left:20px;color:black;font-size:13px"><i style="font-size:13px" class="iconfont icon-shangchuan"></i>上传附件<i style="margin-left:4px" :class="progressLoading?'el-icon-loading':''"></i></span>
                            </el-upload>
                            <span class="file" v-for="(item,index) in fileList" :key="item">
                                <span style="color:orange;margin: 0 5px 0 9px" class="el-icon-paperclip"></span>
                                <span style="width:80px" class="file on-line">{{item}}</span>
                                <i @click="delFile(index)" class="el-icon-close delFile"></i>
                            </span>
                        </el-form-item>
                    </div>
                </el-form>
                <div @mouseenter="autotip" style="flex:1;margin-bottom:50px">
                    <quill-editor 
                    v-model="sendInfo.content" 
                    ref="myQuillEditor" 
                    :options="editorOption" 
                    @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
                    @change="onEditorChange($event)">
                    </quill-editor>
                    <!-- 从数据库读取展示 -->
                    <div v-if="writeMailType!='newMail'" v-dompurify-html="strip"></div>
                    <div v-if="writeMailType!='newMail'" v-dompurify-html="str" style="max-width:900px;margin:10px;max-height:600px;overflow:auto">
                    </div>
                </div>
                <div class="writeFoot">
                    <div>
                        <span class="tempspan">
                            <el-popover
                            placement="top-start"
                            width="500"
                            close-delay='0'
                            @after-leave="nameeditHide"
                            ref="popover"
                            trigger="click">
                            <div class="temp" v-loading='loading.tagLoading'>
                               <div class="muban">
                                   <el-input @input.native="selectTag" v-model="tagKeyword" placeholder="搜索模板名称"></el-input>
                                   <p @mouseenter="enterTemplate(item)" @mouseleave="leaveTemplate" @click="jointContent(item.content)" v-for="item in templateList" :key="item.id">
                                       <span class="on-line" style="width:120px;cursor:pointer">{{item.name}}</span>
                                    </p>
                                    <el-button @click="$router.push('/messageTemplate')" style="width:150px;border-top:1px solid #E4E7ED;position:absolute;left:0;bottom:0" type="text">添加模板</el-button>
                               </div>
                               <div>
                                   <p style="padding-left:15px">模板名称: {{templateName?templateName:'请选择模板'}}</p>
                                    <div v-dompurify-html="templateContent" style="white-space:pre-line;margin-top:10px;font-size:14px;color:#999999;line-height:25px;padding-left:12px" v-show="templateName">
                                    </div>
                                    <div style="text-align:center;margin-top:100px;font-size:14px;color:#999999" v-show="!templateName">
                                        请选择具体模板
                                    </div>
                               </div>
                            </div>
                            <span slot="reference">
                                <span  @click="tagListPage">
                                    选择模板
                                    <i class="el-icon-caret-bottom c"></i>
                                </span>
                            </span>
                            </el-popover>
                        </span>
                        <span style="color:#E6E8ED">|</span>
                        <span @click="sendMail('存草稿')">存草稿</span>
                        <!-- <span style="color:#E6E8ED">|</span>
                        <span>定时发送</span> -->
                    </div>
                    <div>
                        <span>发件邮箱</span>
                        <span style="margin-right:15px">{{accountName?accountName:'该店铺下未绑定邮箱'}}
                            <el-dropdown trigger="click" placement='top'>
                            <i class="el-icon-caret-bottom"></i>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                v-for="item in accountList"
                                :key="item.id"
                                @click.native="getSendAccount(item)">{{item.value}}</el-dropdown-item>
                            </el-dropdown-menu>
                            </el-dropdown>
                        </span>
                        <el-button size="mini" @click="sendMail" type="primary" :loading='loading.buttonLoading'>发送</el-button>
                    </div>
                </div>
            </div>
            <el-dialog
                title="提示"
                :visible.sync="hint"
                width="400px"
                append-to-body
                :before-close="dialogBeforeClose">
                <template slot="title">
                    <div style="padding:20px">提示</div>
                </template>
                <div style="padding:0 0 15px 20px"><span style="color:orange" class="el-icon-warning"></span> 是否保存当前内容至草稿箱？</div>
                <div slot="footer">
                    <el-button @click="hint=false">取 消</el-button>
                    <el-button type="primary" @click="sendMail('存草稿')">保存</el-button>
                    <el-button type="primary" @click="writeMailClose">不保存</el-button>
                </div>
            </el-dialog>
        </el-dialog>
        <textarea id="input">用于复制</textarea>
        <el-upload
        v-show="false"
        class="upload-demo"
        :action="mailImageAction"
        :headers="mytoken"
        :on-success='contentImageSuccess'
        :on-preview="handlePreview"
        :show-file-list='false'
        multiple
        >
            <span class="contentImage" size="small" type="primary">点击上传</span>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
        </el-upload>
    </div>
</template>

<script>
import mailLeftNav from '@/components/mailLeftNav.vue'
import ShopsAndSites from '@/components/ShopsAndSites.vue'
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import quill from 'quill'  // 引入编辑器
import ImageResize from 'quill-image-resize-module';
quill.register('modules/imageResize',ImageResize);

import debounce from '@/utils/delay.js'
import {pageInit,
        tagListPage, 
        sendMsgInit,
        sendMsg,
        leftTree,
        mailLabel,
        getMailBoxMsg,
        getOneMsgById,
        setOneIsRead,
        syncMail,
        timeStam,//时间格式
        removeTag,
        getOneStationMail,
        replied,
        setAllIsRead,
        setCompleted,
        RakuTenMailRply,
        draftInite,
        saveDrafts,
        delDraft,
        matchProductInfo,
        getFile,
        getTicket,
        getSyncStatus,
        isReadRakuten,
        syncMailRakuten,
        mailLabelRelation} from '@/api/Mail/mailMessage.js'
export default {
    components:{
        mailLeftNav,
        quillEditor,
        ShopsAndSites,
        
    },
    data(){
        return {
            leftColor:'',
            mailListColor:'',
            progressLoading:false,
            loadingfull:false,
            leftLoading:false,
            middleLoading:false,
            chatLoading:false,
            syncTime:'',
            sortList:[
                {name:'升序',prop:'asc',icon:false,divided:true},
                {name:'降序',prop:'desc',icon:true,divided:false},
            ],
            // 抄送密送控件
            copyer:false,
            secreter:false,
            // 全屏
            fullscreen:false,
            writeMailShow:false,
            list:[],
            // list:[{name:'站内信',size:11,icon:'icon-amazon',type:'control'}],
            shrink:true,
            activeName:'1',
            activeRight:'first',
            // url: '',
            srcList: [],
            tip:false,
            writeMail:false,
            writeMailType:'',
            str:``,
            titleConfig:[
                {Choice:'.ql-bold',title:'加粗'},
                {Choice:'.ql-italic',title:'斜体'},
                {Choice:'.ql-underline',title:'下划线'},
                {Choice:'.ql-header',title:'段落格式'},
                {Choice:'.ql-strike',title:'删除线'},
                {Choice:'.ql-blockquote',title:'块引用'},
                {Choice:'.ql-code',title:'插入代码'},
                {Choice:'.ql-code-block',title:'插入代码段'},
                {Choice:'.ql-font',title:'字体'},
                {Choice:'.ql-size',title:'字体大小'},
                {Choice:'.ql-list[value="ordered"]',title:'编号列表'},
                {Choice:'.ql-list[value="bullet"]',title:'项目列表'},
                {Choice:'.ql-direction',title:'文本方向'},
                {Choice:'.ql-header[value="1"]',title:'h1'},
                {Choice:'.ql-header[value="2"]',title:'h2'},
                {Choice:'.ql-align',title:'对齐方式'},
                {Choice:'.ql-color',title:'字体颜色'},
                {Choice:'.ql-background',title:'背景颜色'},
                {Choice:'.ql-image',title:'图像'},
                {Choice:'.ql-video',title:'视频'},
                {Choice:'.ql-link',title:'添加链接'},
                {Choice:'.ql-formula',title:'插入公式'},
                {Choice:'.ql-clean',title:'清除字体格式'},
                {Choice:'.ql-script[value="sub"]',title:'下标'},
                {Choice:'.ql-script[value="super"]',title:'上标'},
                {Choice:'.ql-indent[value="-1"]',title:'向左缩进'},
                {Choice:'.ql-indent[value="+1"]',title:'向右缩进'},
                {Choice:'.ql-header .ql-picker-label',title:'标题大小'},
                {Choice:'.ql-header .ql-picker-item[data-value="1"]',title:'标题一'},
                {Choice:'.ql-header .ql-picker-item[data-value="2"]',title:'标题二'},
                {Choice:'.ql-header .ql-picker-item[data-value="3"]',title:'标题三'},
                {Choice:'.ql-header .ql-picker-item[data-value="4"]',title:'标题四'},
                {Choice:'.ql-header .ql-picker-item[data-value="5"]',title:'标题五'},
                {Choice:'.ql-header .ql-picker-item[data-value="6"]',title:'标题六'},
                {Choice:'.ql-header .ql-picker-item:last-child',title:'标准'},
                {Choice:'.ql-size .ql-picker-item[data-value="small"]',title:'小号'},
                {Choice:'.ql-size .ql-picker-item[data-value="large"]',title:'大号'},
                {Choice:'.ql-size .ql-picker-item[data-value="huge"]',title:'超大号'},
                {Choice:'.ql-size .ql-picker-item:nth-child(2)',title:'标准'},
                {Choice:'.ql-align .ql-picker-item:first-child',title:'居左对齐'},
                {Choice:'.ql-align .ql-picker-item[data-value="center"]',title:'居中对齐'},
                {Choice:'.ql-align .ql-picker-item[data-value="right"]',title:'居右对齐'},
                {Choice:'.ql-align .ql-picker-item[data-value="justify"]',title:'两端对齐'}
            ],
            editorOption: {
                placeholder: "请在这里输入",
                modules:{
                    toolbar: {
                        container: [
                            ['italic','bold',  'underline', 'strike'],    //加粗，斜体，下划线，删除线
                            ['blockquote', 'code-block'],     //引用，代码块
                            [{ 'header': 1 ,title:'sdsds'}, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
                            [{ 'script': 'sub'}, { 'script': 'super' }],   // 上下标
                            [{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
                            [{ 'direction': 'rtl' }],             // 文本方向
                            [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
                            [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
                            [{'align': []}],
                            ['clean'],    //清除字体样式
                            //上传图片、上传视频
                            ['link', 'image'],
                        ],
                        handlers: {
                            'image': function (value) {
                                if (value) {
                                    var contentImage = document.getElementsByClassName('contentImage')[0]
                                    contentImage.click()
                                } else {
                                    quill.format('image', false);
                                }
                            }
                        }
                    },
                    imageResize: {
                        displayStyles: {
                        backgroundColor: "black",
                        border: "none",
                        color: "white",
                        },
                        modules: ["Resize", "DisplaySize", "Toolbar"],
                    },
                }
            },
            loading:{
                leftTree:false
            },

            tress:[],
            shopId:'',
            marketPlaceId:'',
            leftMenu:{
                menuList:[],
                commonFolder:[]
            },
            //获取邮件字段
            getMailInfo:{
                current:'1',
                accountId:'',
                boxType:'',
                sort:'desc',
                pageSize:40,
                labelId:'',
                sortColumn:'',
            },
            mailTotal:0,
            //邮件列表
            mailList:[],
            chatBoxInfo:{
                accountId:'',
                sender:'',
                infotype:'',
                contentList:[],
                date:'',
                mailTo:'',
                subject:'',
                content:'',
                fileList:[],
                id:'',
                shopId:'',
                boxType:'',
                cc:'',
            },
            orderMsgRes:{},
            tag:{
                tagName:'',
                tagColor:'#409EFF',
                predefineColors: [
                    '#ff4500',
                    '#ff8c00',
                    '#ffd700',
                    '#90ee90',
                    '#00ced1',
                    '#1e90ff',
                    '#c71585',
                    'rgba(255, 69, 0, 0.68)',
                    'rgb(255, 120, 0)',
                    'hsv(51, 100, 98)',
                    'hsva(120, 40, 94, 0.5)',
                    'hsl(181, 100%, 37%)',
                    'hsla(209, 100%, 56%, 0.73)',
                    '#c7158577'
                ],
                tagShow:false
            },
            // 全选
            checkList:[],
            checkAll:[],
            hint:false,
            accountList:[],
            templateList:[],
            accountName:'',
            sendInfo:{
                accountId:'',
                bccArr:'',
                ccArr:'',
                content:'',//富文本内容
                fileId:[],
                replyToArr:[],
                subject:'',
                toArr:'',
            },
            fileList:[],
            fileListStation:[],
            sortColumn:[],
            templateName:'',
            templateContent:'',
            mailTitle:'',
            tagKeyword:'',
            searchTaginput:'',
            sendStationInfo:{
                accountId:'',
                bccArr:[],
                ccArr:[],
                content:'',
                fileId:[],
                replyToArr:[],
                subject:'',
                toArr:'',
            },
            height:'',
        };
    },
    created(){
        this.getPageInit()
    },
    computed:{
        action(){
            if(this.chatBoxInfo.boxType!='RakuTenMail'){
                return process.env.VUE_APP_API_URL+'/api/file/upload/uploadFile'
            }else{
                return process.env.VUE_APP_API_URL+'/api/MailRakuten/uploadFile'
                
            }
        },
        actionOverall(){
            return process.env.VUE_APP_API_URL+'/api/file/upload/uploadFile'
        },
        mailImageAction(){
            return process.env.VUE_APP_API_URL+'/api/mail/getFilePath';
        },
        mytoken(){
            var token = window.sessionStorage.getItem('token')
            if(this.chatBoxInfo.boxType!='RakuTenMail'){
                return {token:token}
            }else{
                return {shopId:this.shopId,token:token}
            }
        },
        editor() {
            return this.$refs.myQuillEditor.quill;
        },
        mailType(){
            if(this.getMailInfo.boxType=='RakuTenMail'||this.getMailInfo.boxType=='FirstMail'){
                return false;
            }else {
                return true
            }
        },
        getMailType(){
            if(this.getMailInfo.boxType=='RakuTenMail'||this.getMailInfo.boxType=='FirstMail'){
                return false;
            }else {
                return true
            }
        },
        strip(){
            return `<div style="background:#F0F6FF;padding:8px;margin:10px 10px 10px 0;padding-left:20px">${this.chatBoxInfo.date}  ${this.chatBoxInfo.sender}</div>`
        }

    },
    mounted(){
    },
    methods:{
        syncMailRakuten(){
            syncMailRakuten({shopId:this.shopId}).then(res=>{
                console.log('已同步');
            })
        },
        isReadRakuten(id,shopId,type){
            isReadRakuten({id:id,shopId:shopId,type:type}).then(res=>{
                if(res.data.code!=200){
                    this.$message.error(res.data.message)
                }
            })
        },
        autotip() {
            for(let item of this.titleConfig){
                let tip = document.querySelector('.quill-editor '+ item.Choice)
                if (!tip) continue
                tip.setAttribute('title',item.title)
            }
        },
        contentImageSuccess(res,file){
            let quill = this.$refs.myQuillEditor.quill
            if (res.code==200) {
                // 获取光标所在位置===鼠标
                let length = quill.getSelection().index;
                // 插入图片
                quill.insertEmbed(length, 'image', res.data)
                // 调整光标到最后
                quill.setSelection(length + 1)
            } else {
                // 提示信息，需引入Message
                this.$message.error('图片插入失败')
            }
        },
        // 站内信匹配模板
        matchProductInfo(info){
            console.log(1);
            matchProductInfo({
                templateId:info.id,
                orderNo:this.orderMsgRes.orderNumber?this.orderMsgRes.orderNumber:null,
                boxType:this.chatBoxInfo.boxType
            }).then(res=>{
                if(res.data.code!=200){
                    this.$message.error(res.data.message)
                }else {
                    this.sendStationInfo.content+=res.data.data
                }
            })
        },
        delDraft(){
            this.loadingfull=true
            delDraft(this.chatBoxInfo.id).then(res=>{
                this.loadingfull=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.mailListPage()
                    this.chatBoxInfo.boxType='Drafts'     //控制内容区域草稿箱编辑删除显示
                    this.chatBoxInfo.sender=''              //控制内容显示与否
                }else {
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.loadingfull=false
            })
        },
        getDraftInite(){
            this.loadingfull=true
            getOneMsgById(this.chatBoxInfo.id).then(res=>{
                this.loadingfull=false
                if(res.data.code!=200){
                    this.$message.error(res.data.message)
                }else{
                    this.sendInfo.bccArr=res.data.data.bccArr
                    this.sendInfo.ccArr=res.data.data.cc
                    this.sendInfo.toArr=res.data.data.mailTo
                    this.sendInfo.content=res.data.data.content
                    this.sendInfo.fileId=res.data.data.fileList.map(item=>{
                        return item.id;
                    })
                    this.fileList=res.data.data.fileList.map(item=>{
                        return item.fileName
                    })
                    this.sendInfo.subject=res.data.data.subject
                    this.sendMsgInit()
                }
            }).catch(()=>{
                this.loadingfull=false
            })
        },
        //是否完成对话
        setCompleted(info){
            this.middleLoading=true
            setCompleted({
                ids:this.checkList.length==0?[info.id]:this.checkList,
                shopId:info.shopId,
                type:info.type
            }).then(res=>{
                this.middleLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.mailListPage()
                    this.checkList=[]
                }else {
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.middleLoading=false
            })
        },
        // 标记已读未读合一
        setAllIsRead(info,type){
            console.log(info);
            let order = {}
            if(info.type){
                if(info.readType=='marketplace') {
                    order={
                        shopId:this.shopId,
                        marketPlaceId:this.marketPlaceId,
                        type:'marketplace'
                    }
                }else{
                    order={
                        id:info.accountId,
                        type:'account'
                    }
                }
            }else if(info.boxType!='label'){
                order={
                    ids:this.checkList.length==0?[info.id]:this.checkList,
                    signType:info.onRead,
                    type:'mail'
                }
            }else if(info.boxType=='label'&&type=='all'){
                order={
                    id:info.labelId,
                    type:'label'
                }
            }else if(info.boxType=='label'){
                order={
                    ids:this.checkList.length==0?[info.id]:this.checkList,
                    type:'mail',
                    signType:info.onRead,
                }
            }
            this.loadingfull=true
            setAllIsRead(order).then(res=>{
                this.loadingfull=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.mailListPage()
                    // this.getLeftTree()
                    this.checkList=[]
                }else {
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.loadingfull=false
            })
        },
        transpond(info){
            this.writeMailType=info
            this.sendInfo.subject=this.chatBoxInfo.subject
            this.str=this.chatBoxInfo.content
            this.sendInfo.toArr=[]
            this.sendMsgInit(this.chatBoxInfo.id,info)
        },
        replyMail(info){
            this.writeMailType=info
            // this.sendInfo.toArr=this.chatBoxInfo.sender
            this.sendInfo.subject=this.chatBoxInfo.subject
            this.str=this.chatBoxInfo.content
            this.sendMsgInit(this.chatBoxInfo.id,info)
        },
        bigImg(img){
            this.srcList=[img.replace('150','600')]
        },
        replied(info){
            this.chatLoading=true
            replied({
                type:info,
                mailIds:this.chatBoxInfo.boxType=='RakuTenMail'?[]:this.checkList.length==0?[this.chatBoxInfo.id]:this.checkList,
                mailRakutenIds:this.chatBoxInfo.boxType=='RakuTenMail'?this.checkList.length==0?[this.chatBoxInfo.id]:this.checkList:[],
                boxType:this.chatBoxInfo.boxType}).then(res=>{
                this.chatLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                }else{
                    this.$message.error(res.data.message)
                }
                if(info=='noNeedReply'){
                    this.mailListPage()
                }else{
                    this.getMailContent({id:this.chatBoxInfo.id,sender:this.chatBoxInfo.sender,accountId:this.chatBoxInfo.accountId,shopId:this.chatBoxInfo.shopId,boxType:this.chatBoxInfo.boxType})
                }
            }).catch(()=>{
                this.chatLoading=false
            })
        },
        makeTag(info){
            this.loadingfull=true
            mailLabelRelation({labelId:info.labelId,mailIds:this.checkList.length==0?[info.id]:this.checkList,type:'mail'}).then(res=>{
                this.loadingfull=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.mailListPage()
                    this.getLeftTree()
                    this.checkList=[]
                }else {
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.$message.error(res.data.message)
                this.loadingfull=false
            })
        },
        searchTag(){
            const arr = []
            if(this.searchTaginput==''){
                this.$set(this.leftMenu,'selectTagList',this.leftMenu.tagList)
                return
            }
            this.leftMenu.tagList.forEach(item=>{
                if(item.name.includes(this.searchTaginput)){
                    arr.push(item)
                }
            })
            this.$set(this.leftMenu,'selectTagList',arr)
        },
        selectTag(){
            this.tagListPage()
        },
        // 名字写串了
        tagListPage(){
            this.$set(this.loading,'tagLoading',true)
            tagListPage({keyword:this.tagKeyword}).then(res=>{
                this.$set(this.loading,'tagLoading',false)
                this.templateList=res.data.values
            }).catch(()=>{
                this.loadingfull=false
                this.$set(this.loading,'tagLoading',false)
            })
        },
        nameeditClick(scope) {
            this.$refs['popover'].doClose()
            this.tagKeyword=''
        },
        jointContent(info){
            const editor = document.getElementsByClassName('ql-editor')[0]
            editor.children[0].innerHTML+=info
            this.nameeditClick()
        },
        jointContentStation(info){
            // const editor = document.getElementsByClassName('ql-editor')[0]
            // editor.children[0].innerHTML+=info
            this.sendStationInfo.content=info
        },
        enterTemplate(info){
            this.templateName=info.name
            this.templateContent=info.content
        },
        leaveTemplate(){
            this.templateName=''
            this.templateContent=''
        },
        queryDraft(){
            if(this.writeMailType=='newMail'){
                if(this.sendInfo.content==""&&this.sendInfo.subject==""){
                    this.writeMailClose()
                }else {
                    this.hint=true
                }
            }else {
                if((this.sendInfo.subject).indexOf('Re')>=0&&this.sendInfo.content==''){
                    this.writeMailClose()
                }else {
                    this.hint=true
                }
            }
            
        },
        delFileStation(index){
            this.sendStationInfo.fileId.splice(index,1)
            this.fileListStation.splice(index,1)
        },
        delFile(index){
            this.sendInfo.fileId.splice(index,1)
            this.fileList.splice(index,1)
        },
        uploadError(){
            this.loadingfull=false
            this.progressLoading=false
            this.$message.error('上传失败')
        },
        uploadProgressStation(){
            this.progressLoading=true
            this.loadingfull=true
        },
        uploadProgress(){
            this.progressLoading=true
        },
        uploadSuccessStation(res,file,fileList){
            this.progressLoading=false
            this.loadingfull=false


            if(this.chatBoxInfo.boxType=='RakuTenMail'){
                if(res.code==200){
                    this.$message.success(res.message)
                    this.sendStationInfo.fileId=[res.data.result]
                    this.fileListStation=[res.data.result]
                }else{
                    this.$message.warning(res.message)
                }
            }else {
                if(res.code==200){
                    this.$message.success(res.message)
                    this.sendInfo.fileId.push(res.data.fileId)
                    this.fileListStation.push(file.name)
                }else{
                    this.$message.warning(res.message)
                }
            }
        },
        uploadSuccess(res,file,fileList){
            this.progressLoading=false
            if(res.code==200){
                this.$message.success(res.message)
                this.sendInfo.fileId.push(res.data.fileId)
                this.fileList.push(file.name)
            }else{
                this.$message.warning(res.message)
            }
        },
        getSendAccount(item){
            this.sendInfo.accountId=item.id
            this.accountName=item.value
        },
        sendMsgInit(mailId,type){
            this.loadingfull=true
            sendMsgInit({shopId:this.shopId,
                marketPlaceId:this.marketPlaceId,
                mailId:mailId?mailId:'',
                type:type?type:null}
                ).then(res=>{
                if(res.data.code==200){
                    this.loadingfull=false
                    this.writeMailShow=true
                    this.writeMail=true
                    this.accountList=res.data.data.accountList
                    this.templateList=res.data.data.templateList
                    if(res.data.data.replyMsg){
                        if(this.writeMailType=='Re'||this.writeMailType=='ReAll'){
                            this.sendInfo.toArr=res.data.data.replyMsg.showMailTo
                        }
                        this.sendInfo.subject=res.data.data.replyMsg.subject
                    }
                    const flag = this.accountList.some(item=>{
                        if(item.isSelected==1){
                            this.accountName=item.value
                            this.sendInfo.accountId=item.id
                        }
                        return item.isSelected==1
                    })
                    if(!flag) {
                        this.sendInfo.accountId=this.accountList[0].id
                        this.accountName=this.accountList[0].value
                    }
                }else {
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.loadingfull=false
            })
        },
        sendStationMail(){
            this.$set(this.loading,'buttonLoading',true)
            if(this.chatBoxInfo.boxType!='RakuTenMail'){
                sendMsg(this.sendStationInfo).then(res=>{
                    this.$set(this.loading,'buttonLoading',false)
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.sendStationInfo.content=''
                        this.sendStationInfo.fileId=[]
                        this.fileListStation=[]
                        this.getMailContent({id:this.chatBoxInfo.id,sender:this.chatBoxInfo.sender,accountId:this.chatBoxInfo.accountId})
                    }else {
                        this.$message.error(res.data.message)
                    }
                }).catch(()=>{
                    this.$set(this.loading,'buttonLoading',false)
                })
            }else{
                RakuTenMailRply({
                    attachments:this.fileListStation,
                    id:this.chatBoxInfo.id,
                    message:this.sendStationInfo.content,
                    shopId:this.chatBoxInfo.shopId,
                }).then(res=>{
                    this.$set(this.loading,'buttonLoading',false)
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.sendStationInfo.content=''
                        this.sendStationInfo.fileId=[]
                        this.fileListStation=[]
                        this.getMailContent({id:this.chatBoxInfo.id,sender:this.chatBoxInfo.sender,accountId:this.chatBoxInfo.accountId,shopId:this.shopId,boxType:this.chatBoxInfo.boxType})
                    }else {
                        this.$message.error(res.data.message)
                    }
                }).catch(()=>{
                    this.$set(this.loading,'buttonLoading',false)
                })
            }
            
        },
        toTop(info){
            if(info=="mailList"){
                const Dom = document.getElementsByClassName('mailContent')[0]
                Dom.scrollTop = 0
            }else {
                this.$nextTick(()=>{
                    const Dom = document.getElementsByClassName('mailText')[0]
                    if(!Dom) return
                    Dom.scrollTop = 0
                })
            }

        },
        toBottom(){
            this.$nextTick(()=>{
                let ele = document.getElementById('chatb');
                ele.scrollTop = ele.scrollHeight;
            })
        },
        // 存草稿
        saveDrafts(id){
            this.writeMailShow=false
            this.loadingfull=true
            saveDrafts({...this.sendInfo,id:id}).then(res=>{
                this.writeMailClose()
                this.loadingfull=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                }else{
                    this.loadingfull=false
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.loadingfull=false
            })
        },
        /**
         * 需要对抄送人发件人进行处理
         * 草稿同样需要
         * 放在了一起
         * ><  回显文字显示问题
         */
        sendMail(info){
            var toArr=[]
            var bccArr=[]
            var ccArr=[]
            this.sendInfo.toArr= this.sendInfo.toArr.replace(/\s+/g, "");
            this.sendInfo.bccArr= this.sendInfo.bccArr.replace(/\s+/g, "");
            this.sendInfo.ccArr= this.sendInfo.ccArr.replace(/\s+/g, "");

            if(!this.sendInfo.toArr) return this.$message.error('请输入收件人');

            if(this.sendInfo.toArr && this.sendInfo.toArr.indexOf(';')>=0){
                toArr = this.sendInfo.toArr.split(';').map(item=>{
                    item =item.includes('<')?item.substring(item.indexOf('<')+1,item.lastIndexOf('>')):item
                    return item
                })
            }else {
                toArr =this.sendInfo.toArr? this.sendInfo.toArr.includes('<')?[this.sendInfo.toArr.substring(this.sendInfo.toArr.indexOf('<')+1,this.sendInfo.toArr.lastIndexOf('>'))]:[this.sendInfo.toArr] :[]
            }
            if(toArr[toArr.length-1]=='') toArr.splice(toArr.length-1,1)


            if(this.sendInfo.bccArr && this.sendInfo.bccArr.indexOf(';')>=0){
                bccArr = this.sendInfo.bccArr.split(';').map(item=>{
                    item =item.includes('<')?item.substring(item.indexOf('<')+1,item.lastIndexOf('>')):item
                    return item
                })
            }else {
                bccArr =this.sendInfo.bccArr? this.sendInfo.bccArr.includes('<')?[this.sendInfo.bccArr.substring(this.sendInfo.bccArr.indexOf('<')+1,this.sendInfo.bccArr.lastIndexOf('>'))]:[this.sendInfo.bccArr] :[]
            }
            if(bccArr[bccArr.length-1]=='') bccArr.splice(bccArr.length-1,1)

            if(this.sendInfo.ccArr&&this.sendInfo.ccArr.indexOf(';')>=0){
                ccArr = this.sendInfo.ccArr.split(';').map(item=>{
                    item =item.includes('<')?item.substring(item.indexOf('<')+1,item.lastIndexOf('>')):item
                    return item
                })
            }else {
                ccArr =this.sendInfo.ccArr? this.sendInfo.ccArr.includes('<')?[this.sendInfo.ccArr.substring(this.sendInfo.ccArr.indexOf('<')+1,this.sendInfo.ccArr.lastIndexOf('>'))]:[this.sendInfo.ccArr] :[]
            }
            if(ccArr[ccArr.length-1]=='') ccArr.splice(ccArr.length-1,1)

            
            const regular = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
            const flagtoArr=toArr.length==0?false:toArr.some(item=>{
                return regular.test(item)==false;
            })
            const flagbccArr=bccArr.length==0?false:bccArr.some(item=>{
                return regular.test(item)==false;
            })
            const flagccArr=ccArr.length==0?false:ccArr.some(item=>{
                return regular.test(item)==false;
            })
            if(flagtoArr||flagbccArr||flagccArr){
                this.$message.error('邮箱格式错误 提示 用 ; 隔开或邮箱本身书写')
                return
            }

            
            if(info=='存草稿'){
                this.$set(this.loading,'buttonLoading',true)
                this.writeMailShow=false
                this.loadingfull=true
                saveDrafts({
                    ...this.sendInfo,
                    content:this.sendInfo.content,
                    toArr:toArr,
                    ccArr:ccArr,
                    bccArr:bccArr,
                    id:this.chatBoxInfo.id?this.chatBoxInfo.id:null
                    }).then(res=>{
                    this.writeMailClose()
                    this.loadingfull=false
                    this.$set(this.loading,'buttonLoading',false)
                    if(res.data.code==200){
                        if(this.getMailInfo.accountId) this.mailListPage()
                        this.$message.success(res.data.message)
                        if(this.chatBoxInfo.id){
                            this.chatBoxInfo={
                                accountId:'',
                                sender:'',
                                infotype:'',
                                contentList:[],
                                date:'',
                                mailTo:'',
                                subject:'',
                                content:'',
                                fileList:[],
                                id:'',
                                shopId:'',
                                boxType:'Drafts',
                                cc:'',
                            }
                        } 
                    }else{
                        this.loadingfull=false
                        this.$message.error(res.data.message)
                    }
                }).catch(()=>{
                    this.loadingfull=false
                })
            }else{
                if(!this.sendInfo.accountId) return this.$message.error('该店铺下未绑定账号')
                this.$set(this.loading,'buttonLoading',true)
                sendMsg({...this.sendInfo,
                    content:this.writeMailType=='Re'||this.writeMailType=='Fw'?this.sendInfo.content+this.strip+this.chatBoxInfo.content:this.sendInfo.content,
                    toArr:toArr,
                    ccArr:ccArr,
                    bccArr:bccArr
                }).then(res=>{
                    this.$set(this.loading,'buttonLoading',false)
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.getLeftTree()
                        // this.mailListPage()
                        this.writeMailClose()
                    }else {
                        this.$message.error(res.data.message)
                    }
                }).catch(()=>{
                    this.$set(this.loading,'buttonLoading',false)
                })
            }
        },
        newMail(){
            this.writeMailType='newMail'
            this.sendMsgInit()
        },
        notsave(){
            this.writeMail=false
            this.hint=false
            this.writeMailShow=true
        },
        selectAll(){
            if(this.checkAll[0]==1){
                this.mailList.forEach(item=>{
                    this.checkList.push(item.id)
                })
            }else {
                this.checkList=[]
            }
            if(!this.chatBoxInfo.sender){
                this.getMailContent(this.mailList[0])
            }
        },
        select(){
            if(this.checkList.length==this.mailList.length){
                this.checkAll=['1']
            }else {
                this.checkAll=[]
            }
        },
        TagBox(){
            this.tag.tagShow=!this.tag.tagShow
        },
        addTag(){
            if(!this.tag.tagName){
                this.$message.warning('请输入标签名称')
                return 
            }
            this.leftLoading=true
            mailLabel({
                color:this.tag.tagColor,
                marketPlaceId:this.marketPlaceId,
                shopId:this.shopId,
                name:this.tag.tagName
            }).then(res=>{
                this.leftLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.TagBox()
                    this.getLeftTree()
                    this.tag.tagName=''
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.leftLoading=false
            })
        },
        sort(info,index){
            this.getMailInfo.sort=info.prop
            this.sortList[index].icon=!this.sortList[index].icon
            this.sortList.forEach((item,ind)=>{
                if(index!=ind){
                    this.sortList[ind].icon=false
                }
            })
            this.mailListPage()
        },
        sortMail(info,ind){
            this.mailTitle=info.value
            this.getMailInfo.sortColumn=info.id
            this.mailListPage()
        },
        down(info){
            this.loadingfull=true
            getFile(info.id).then(res=>{
                this.loadingfull=false
                const blob = new Blob([res.data], { type: 'application/vnd.ms-excel application/x-excel;charset=utf-8'});
                const downloadElement = document.createElement('a');
                const href = window.URL.createObjectURL(blob); // 创建下载的链接
                downloadElement.href = href;
                downloadElement.download =info.fileName; // 下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); // 点击下载
                document.body.removeChild(downloadElement); // 下载完成移除元素
                window.URL.revokeObjectURL(href); // 释放掉blob对象
            })
        },
        downAll(){
            this.chatBoxInfo.fileList.forEach(item=>{
                this.down(item)
            })
        },
        mailSync(){
            this.$set(this.loading,'syncIcon',true)
            const that = this
            // debounce(this.)
            this.syncMailRakuten() //乐天同步
            syncMail({shopId:this.shopId,marketPlaceId:this.marketPlaceId}).then(res=>{
                console.log('同步了');
            })
            var timer = setTimeout(()=>{
                getSyncStatus({shopId:this.shopId,marketPlaceId:this.marketPlaceId}).then(res=>{
                    this.$set(this.loading,'syncIcon',false)
                    if(res.data.code==200){
                        this.$message.success(res.data.message)
                        this.syncTime=res.data.data
                        this.mailList=[]
                        // this.mailListPage()
                        this.getLeftTree()
                        clearTimeout(timer)
                    }else {
                        this.$message.error(res.data.message)
                        clearTimeout(timer)
                    }
                })
            },6000)
        },
        getID(shopID,marketPlaceId){
            this.shopId=shopID
            this.marketPlaceId=marketPlaceId
            // console.log(shopID,marketPlaceId,this.pageInfo);
            this.getLeftTree()
        },
        commonControl(val){
            // this.$set(this.leftMenu,'control',false)
            // console.log(this.leftMenu,val);
            // const arr = []
            // this.leftMenu.common.forEach(item=>{
            //     val.forEach(i=>{
            //         if(item.name==i.name) arr.push(item)
            //     })
            // })
            // this.leftMenu.commonFolder=arr
            // this.$set(this.leftMenu,'control',true)
            // console.log(this.leftMenu.commonFolder);
        },
        getMailContent(info,index){
            console.log(1);
            this.mailListColor=info.id
            this.sendInfo={
                accountId:'',
                bccArr:'',
                ccArr:'',
                content:'',//富文本内容
                fileId:[],
                replyToArr:[],
                subject:'',
                toArr:'',
            }
            this.fileListStation=[]
            this.toTop('mailContent')
            this.chatBoxInfo.id=info.id
            this.chatBoxInfo.read=info.read
            this.chatLoading=true
            this.chatBoxInfo.shopId=info.shopId
            this.sendStationInfo.content=''
            if(this.chatBoxInfo.boxType=='FirstMail'||this.chatBoxInfo.boxType=='RakuTenMail'){
                this.orderMsgRes={}  //清空站内信订单商品信息
                getOneStationMail({
                    accountId:this.chatBoxInfo.boxType=='FirstMail'?info.accountId:null,
                    sender:this.chatBoxInfo.boxType=='FirstMail'?info.sender:null,
                    id:this.chatBoxInfo.boxType=='RakuTenMail'?this.chatBoxInfo.id:null,
                    shopId:this.chatBoxInfo.boxType=='RakuTenMail'?this.chatBoxInfo.shopId:null,
                    boxType:this.chatBoxInfo.boxType}).then(res=>{
                    this.chatLoading=false
                    if(res.data.code!=200){
                        this.$message.error(res.data.message)
                        return ;
                    }
                    if(this.chatBoxInfo.boxType=='RakuTenMail'&&!info.read) this.isReadRakuten(info.id,this.shopId,info.boxType)
                    this.chatBoxInfo.contentList=res.data.data.msgList
                    this.chatBoxInfo.subject=res.data.data.subject
                    this.chatBoxInfo.sender=res.data.data.sender
                    this.chatBoxInfo.cc=res.data.data.cc
                    this.chatBoxInfo.senderNick=res.data.data.senderNick
                    this.orderMsgRes=res.data.data.orderMsgRes
                    this.sendStationInfo.toArr=[res.data.data.sender]
                    this.sendStationInfo.accountId=res.data.data.accountId
                    this.sendStationInfo.subject=res.data.data.subject

                    this.chatBoxInfo.accountId=res.data.data.accountId//站内信发送需要
                    this.toBottom() //发送完消息自动到底部
                    // this.mailListPage()
                    this.mailList[index].read=true
                    // this.getLeftTree()

                }).catch(()=>{
                    this.chatLoading=false
                })
            }else{
                console.log(2);
                
                getOneMsgById(info.id).then(res=>{
                    if(res.data.code!=200){
                        this.$message.error(res.data.message)
                        return ;
                    }

                    this.chatBoxInfo.id=res.data.data.id
                    this.chatBoxInfo.sender=res.data.data.sender
                    this.chatBoxInfo.date=res.data.data.date
                    this.chatBoxInfo.cc=res.data.data.cc
                    this.chatBoxInfo.mailTo=res.data.data.mailTo
                    this.chatBoxInfo.subject=res.data.data.subject
                    this.chatBoxInfo.content=res.data.data.content.replace(/href=/g,"target='_blank' href=")
                    // this.chatBoxInfo.boxType=res.data.data.boxType
                    this.chatBoxInfo.fileList=res.data.data.fileList.map(item=>{
                        const index = item.fileName.lastIndexOf('.')
                        const str = item.fileName.substring(index+1)
                        item.icoType=str
                        return item;
                    })
                    this.mailList[index].read=true
                    const timer = setTimeout(()=>{
                        
                        // console.log(this.chatBoxInfo.content.indexOf('href'));
                        console.log(document.getElementById("myiframe").contentWindow.document.body.clientHeight);
                        document.getElementById("myiframe").height=document.getElementById("myiframe").contentWindow.document.activeElement.clientHeight+50
                        console.log(document.getElementById("myiframe").contentWindow); 
                        this.chatLoading=false
                        clearTimeout(timer)
                    },500)
                }).catch(()=>{
                    this.chatLoading=false
                })
            }
        },
        mailListCurrent(val){
            this.toTop('mailList')
            this.getMailInfo.current=val
            this.mailListPage()
        },
        mailListPage(){
            this.mailListColor=''
            this.checkList=[]
            this.checkAll=[]
            this.middleLoading=true
            getMailBoxMsg({...this.getMailInfo,shopId:this.shopId,marketPlaceId:this.marketPlaceId}).then(res=>{
            this.middleLoading=false
                if(res.data.code==500){
                    this.$message.error(res.data.message)
                }else {
                    this.mailList=res.data.values.map(item=>{
                        if(((new Date().getTime())-item.timeStamp)<86400000){
                            console.log(1);
                            item.outTime = timeStam(86400000 - ((new Date().getTime())-item.timeStamp))
                            item.outToday=true
                        }else{
                            // console.log(2);
                            item.outToday=false
                        }
                        return item
                    })
                    this.mailTotal=res.data.pageInfo.total
                    if(this.getMailInfo.sortColumn){
                        let flag = res.data.mapInfo.sortList.some(item=>{
                            return item.value==this.mailTitle
                        })
                        if(!flag){
                            this.sortColumn=res.data.mapInfo.sortList.map((item,index)=>{
                                if(index==0){
                                    item.icon=true
                                    this.mailTitle=item.value
                                    this.getMailInfo.sortColumn=item.id
                                }
                                return item
                            })
                            
                        }else {
                            this.sortColumn=res.data.mapInfo.sortList.map((item,index)=>{
                                if(item.id==this.getMailInfo.sortColumn){
                                    item.icon=true
                                    this.mailTitle=item.value
                                    this.getMailInfo.sortColumn=item.id
                                }
                                return item
                            })
                        }
                    }else {
                        this.sortColumn=res.data.mapInfo.sortList.map((item,index)=>{
                            if(index==0){
                                item.icon=true
                                this.mailTitle=item.value
                                this.getMailInfo.sortColumn=item.id
                            }
                            return item
                        })
                    }
                }
            }).catch(()=>{
                this.middleLoading=false
            })
        },
        getMail(info){
            this.leftColor=info.id
            this.chatBoxInfo={
                accountId:'',
                sender:'',
                infotype:'',
                contentList:[],
                date:'',
                mailTo:'',
                subject:'',
                content:'',
                fileList:[],
                id:'',
                shopId:'',
                boxType:'',
                cc:'',
            }
            this.chatBoxInfo.boxType=info.boxType
            this.getMailInfo.accountId=info.accountId
            this.getMailInfo.boxType=info.boxType
            this.getMailInfo.sortColumn=''
            this.mailListPage()
        },
        getMailByTag(info){
            this.chatBoxInfo={
                accountId:'',
                sender:'',
                infotype:'',
                contentList:[],
                date:'',
                mailTo:'',
                subject:'',
                content:'',
                fileList:[],
                id:'',
                shopId:'',
                boxType:'',
                cc:'',
            }
            this.leftColor=info.id
            this.getMailInfo.labelId=info.labelId
            this.getMailInfo.boxType=info.boxType
            this.chatBoxInfo.boxType=info.boxType
            this.mailListPage()
        },
        writeMailClose(){
            this.sendInfo={
                accountId:'4',
                bccArr:'',
                ccArr:'',
                content:'',//富文本内容
                fileId:[],
                replyToArr:[],
                subject:'',
                toArr:'',
            }
            this.fileList=[]
            this.str=""
            this.writeMailType=''
            this.writeMail=false
            this.writeMailType=''
            this.hint=false
        },
        // 查询初始化
        async getPageInit(){
            this.leftLoading=true
            const {data} = await pageInit()
            this.tress=data.data.tress
            this.tress.forEach(item=>{
                item.marketPlaceTree.forEach(i=>{
                    if(i.isSelected==1){
                        this.shopId=item.id
                        this.marketPlaceId=i.id
                    }
                })
            })
            this.getLeftTree()
            // this.sendMsgInit()`
        },
        // 获取做侧菜单
        getLeftTree(){
            this.leftLoading=true
            leftTree({shopId:this.shopId,marketPlaceId:this.marketPlaceId}).then(res=>{
                this.leftLoading=false
                if(res.data.code==200){
                    this.leftMenu=res.data.data.children
                    this.$set(this.leftMenu,'list',[{...this.leftMenu[0],type:'control'},{...this.leftMenu[1],type:'control'}])
                    this.$set(this.leftMenu,'tagList',this.leftMenu[4].children)
                    this.$set(this.leftMenu,'common',this.leftMenu[2].children)
                    this.leftMenu.commonFolder=this.leftMenu.common
                    this.$set(this.leftMenu,'mailList',this.leftMenu[3].children)
                    this.syncTime=res.data.mapInfo.time
                    this.$set(this.leftMenu,'selectTagList',this.leftMenu[4].children)
                    console.log(this.leftMenu.list);
                }
            }).catch(()=>{
                this.leftLoading=false
            })
            this.$set(this.leftMenu,'control',true)
        },
        onEditorReady(editor) { // 准备编辑器
 
        },
        onEditorBlur(){}, // 失去焦点事件
        onEditorFocus(){}, // 获得焦点事件
        onEditorChange(){}, // 内容改变事件
        collapseChange(){
            this.shrink=!this.shrink
            const ss=document.getElementsByClassName('dd')
        },
        copy(){
            var text = document.getElementById("copy").innerText;
            input.value = text; 
            input.select(); 
            document.execCommand("copy"); 
            this.$message.success('复制成功')
        },
        removeTag(id){
            this.$confirm('删除将会清除所有邮件包含此标签的标识, 不可恢复?', '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loadingfull=true
                removeTag(id).then(res=>{
                    this.loadingfull=false
                    if(res.data.code==200){
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getLeftTree()
                        this.mailListPage()
                        this.getMailContent()
                    }else{
                        this.$message({
                            type: 'error',
                            message:res.data.message
                        });
                    }
                })
            }).catch(() => {
                this.loadingfull=false
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        closeInput(info){
            if(info=='抄送人'){
                this.copyer=!this.copyer
                this.sendInfo.ccArr=''
            }else {
                this.secreter=!this.secreter
                this.sendInfo.bccArr=''
            }
        }
    }
}
</script>

<style scoped lang='scss'>
.bodyfull{
    background: white !important;
}
.wrapper {position: relative;}
#input {position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;}
::v-deep .ql-blank{
    border:0;
    border-top: 1px solid #E4E8ED;
    max-height: 300px;
    overflow: auto;
}
::v-deep .ql-snow{
    border:0;
}
::v-deep .ql-editor p{
    overflow: auto;
    white-space: pre-line;
    // margin-bottom: 90px;
}
.ql-editor{
    min-height: 150px;
}
::v-deep .el-dialog__body{
    padding: 0;
}
::v-deep .el-dialog__header{
    padding:0;
}
::v-deep .writeBox .el-input__inner{
    border: 0;
    background: transparent;
}
::v-deep .writeBox .el-form-item{
    padding-bottom: 0;
    margin-bottom: 0;
}
::v-deep .writeBox .el-form-item__label{
    color: #888888;
}
.writeFoot{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 9px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
        padding: 0 2px;
        cursor: pointer;
    }
}
.writeBox{
    >div{
        border-bottom: 1px solid #E6E8ED;
        position: relative;
        .send{
            position: absolute;
            cursor: pointer;
            z-index: 99;
            right:16px;
            top:10px;
            a{
                margin-left: 10px;
            }
            span{
                cursor: pointer;
            }
        }
    }
}
 .temp{
    height: 450px;
    width: 100%;
    display: flex;
    position: relative;
    >div{
        display:inline-block;
        height: 100%;
    }
    >div:nth-of-type(1){
        >p{
            line-height: 25px;
            padding-left: 8px;
            cursor: pointer;
            // width: 130px;
        }
        >p:hover{
            background: #F7F7F7;
        }
    }
    >div:nth-of-type(2){
        width: 350px;
        // display: none;
        border-left: 1px #E4E7ED solid;
    }
}
::v-deep .muban{
    .el-input__inner{
        border: 0;
        border-bottom: 1px #E4E7ED solid;
    }
}
.headerStyle{
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #E4E8ED;
    span{
        margin-right:4px;
        color:#999999;
    }
    span:hover{
        color: black;
        font-weight: 700;
    }
}
.right{
    width: 300px;
    .order{
        padding: 7px;
        position: relative;
    }
}
.goods{
    display: flex;
    margin-top: 10px;
}
.type{
    display: flex;
    flex-wrap: wrap;
    div{
        margin-right:10px
    }
}
::v-deep .el-tabs__item .is-top{
    padding: 0 6px;
}
.coc{
    cursor: pointer;
}
.biaoti{
    font-size:18px;font-weight:800;padding-left:30px;
    white-space: nowrap;
    padding-left: 24px;
    overflow:hidden;
    line-height: 34px;
    max-width: 790px;
    text-overflow: ellipsis;
    margin-right: 20px;
}
.operate{
    background: white;
    display: flex;
    height: 40px;
    align-items: center;
    padding-left: 20px;
    color: black !important;
    // position: absolute;
    // left: 0;
    // top: 0;
    // right: 0;
    div{
        padding: 6px 9px;
        border-radius: 19px;
        margin-right: 15px;
        font-size: 14px;
        cursor: pointer;
    }
    div:hover{
        background: #EFF6FF;
    }
}
p,span{
    font-size: 14px;
    color: #606266;
}
::v-deep .el-input-group__append{
    background: white;
    width:10px;
}
::v-deep .el-input-group__prepend{
    background: white;
    width:10px;
    display: block;
}
.top{
    position: absolute;
    top:60px;
    left:0;
    right:0;
    padding-left: 20px;
    box-shadow: 0px 12px 12px -20px rgba(0, 0, 0, 0.3);
}
.content{
    position: absolute;
    bottom: 5px;
    left: 0;
    top: 106px;
    right: 0;
    display: flex;
}
.left{
    width:270px;
    height: 100%;
    border-right: 1px solid #E4E7ED;
    background: #F7F8F9;
    position: relative;
    padding-top: 5px;
    .time{
        overflow: hidden;
        background: #F7F8F9;
        color:#AAAAAA;font-size:12px;
        text-align: center;
        padding:10px 10px 10px 15px;
        border-bottom:1px solid #E4E7ED;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
    }
    .navLeft{
        position: absolute;
        top: 77px;
        bottom: 0;
        left: 0;
        right: 0;
        // background: red;
        overflow: auto;
        padding-top: 10px;
        box-sizing: border-box;
    }
}
.middle{
    width: 340px;
    border-right: 1px solid #E4E7ED;
    background: #FDFDFD;
    position: relative;
    .pagination{
        width: 100%;
        height: 30px;
        text-align:right;margin:8px 0;
        position: absolute;
        right: 0;
        bottom: 0;
        background: #FDFDFD;
        padding: 5px 0 0 0;
    }
    .selectAll{
        height: 32px;
        width: 100%;
        background: white;
        padding:6px;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
    }
    .mailContent{
        position: absolute;
        z-index: 1;
        top: 32px;
        bottom: 40px;
        left: 0;
        right: 0;
        overflow: auto;
    }
    .item{
        width: 100%;
        padding: 6px;
        cursor: pointer;
        box-sizing: border-box;
        p{
            overflow: hidden;
            box-sizing: border-box;
            width: 240px;
            white-space: nowrap;
            padding-left: 24px;
            line-height: 34px;
            flex: 1;
            max-width: 290px;
            text-overflow: ellipsis;
        }
        .item-text{
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .noneed{
            display: inline-block;
            background: #F5FCC5;
            color: #5BBC2A;
            padding: 2px 3px;
            border-radius: 5px;
            margin-left: 6px;
            vertical-align: middle;
        }
        .outTime{
            display: inline-block;
            background: red;
            color: white;
            padding: 2px 3px;
            height: 15px;
            border-radius: 5px;
        }
        .more{
            display: none;
        }
        .more:hover{
            font-weight: bold;
        }
        .not{
            display: inline-block;
            width: 9px;
            height: 9px;
            background: #006EFF;
            border-radius: 50%;
            vertical-align: middle;
        }
    }
}
.chat{
    flex: 1;
    border-right: 1px solid #E4E7ED;
    position: relative;
    height: 100%;
    // display: flex;
    // flex-direction: column;
}
::v-deep .chatInput{
    background: white;
    position: absolute;
    border-top:1px solid #EBEEF5;
    height: 130px;
    bottom: 0;
    right: 0;
    left: 0;
    .el-textarea__inner{
        border: 0;
        resize: none
    }
    .butt{
        position: absolute;
        right: 9px;
        bottom: 8px;
    }

}
.item:hover{
    background: #f2f2f2;
    .more{
        display: block;
    }
}
::v-deep .el-collapse-item__content{
    padding-bottom: 0;
}
::v-deep .el-tabs__item.is-active{
  color: #40B8FF;
}
::v-deep .el-tabs__active-bar{
  background-color: #40B8FF;
}
::v-deep .el-tabs__nav-scroll{
    padding-left: 20px;
}
::v-deep .el-tree{
    background: transparent;
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
::v-deep .custom-tree-node{
    padding-right: 0;
    .more{
        display: none;
    }
    :hover .more{
        display: inline;
    }
    .one{
        padding-left: 0 !important;
    }
}
::v-deep .el-tree__empty-block{
    display: none;
}
.on-line{
    display: inline-block;
    min-width: 10px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow:ellipsis;
    // vertical-align: middle;
}
::v-deep .el-tree-node__content>.el-tree-node__expand-icon{
    padding: 0;
}
::v-deep .el-color-picker__trigger{
    padding: 0;
    border: 0;
    width: 30px;
    height: 33px;
    position: relative;
    top: -1px;
}
::v-deep .el-color-picker__color{
    border: 1px solid #E4E7ED;
    border-right: 0;
}
::v-deep .colorBox{
    margin:8px 4px 0 15px;
    display: flex;
    align-items: center;
    >span{
        font-size: 24px;
        margin:0 8px;
        cursor: pointer;
        color: #0079FF;
    }
    .el-input__inner{
        border: 0;
        background: transparent;
        border: 1px solid #E4E7ED;
        border-radius: 0;

    }
}
.file {
    display: inline-block;
    background: #EEEEEE;
    border-radius: 19px;
    height: 30px;
    line-height: 30px;
    padding-right: 10px;
    margin-right: 5px;
    >span{
        display: inline-block;
        vertical-align: middle;
    }
    .delFile{
        display:inline-block;
        width: 12px;
        height: 12px;
        font-size: 11px;
        vertical-align: middle;
        cursor: pointer;
    }
}
.delFile:hover {
    color: white;
    background: #999999;
    border-radius: 50%;
}
.tagColor{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    vertical-align: middle;
}
.tagMore{
    display: none;
    cursor: pointer;
}
.itemTag:hover{
    background: #f2f2f2;
    .tagMore{
        display: inline;
    }
}
.mailListTag{
    padding-left:24px;
    >span{
        display: inline-block;
        padding: 1px 2px;
        margin-right: 5px;
        border-radius: 4px;
        cursor: pointer;
        font-size:12px !important;
    }
}
::v-deep .addTag .el-input__inner{
    border: 0;
    border-bottom: 1px solid #E4E7ED;
    margin-bottom:7px;
}
.msgBox{
    overflow: hidden;
    >div{
        max-width: 50%;
        min-width:170px;
        margin:10px 30px 10px 15px;
        position: relative;
        .replyIcon{
            padding: 0;
            margin: 0;
            position: absolute;
            right: -34px;
            top: 25px;
            color: #90EE90;
            font-size: 16px;
        }
        >span{
            padding-left: 6px;
            margin-bottom: 9px;
        }
        >div{
            border-radius: 7px;
            width: 100%;
            padding: 8px;
            line-height: 20px;
            margin-top: 6px;
            font-size: 14px;
            white-space: pre-wrap;
        }
    }
}
.isCompleted{
    display: inline-block;
    border-radius: 5px;
    padding: 1px 3px;
}
</style>