import request from '@/utils/request'

export function pageInit() {
    return request({
        url:'/api/mail/pageInit',
        method:'get'
    });
}
export function leftTree(params) {
    return request({
        url:`/api/mail/getLeftTree`,
        method:'get',
        params
    });
}

export function getMailBoxMsg(params) {
    return request({
        url:`/api/mail/getMailBoxMsg`,
        method:'get',
        params
    });
}
export function getOneMsgById(id) {
    return request({
        url:`/api/mail/getOneMsgById/${id}`,
        method:'get',
    });
}



// 同步收件箱
export function syncMail(params) {
    return request({
        url:'/api/mail/sync',
        params,
        method:'get'
    });
}
export function getSyncStatus(params) {
    return request({
        url:'/api/mail/getSyncStatus',
        params,
        method:'get',
    });
}

// 乐天同步
export function syncMailRakuten(params) {
    return request({
        url:'/api/MailRakuten/syncMail',
        method:'get',
        params
    });
}


// 新增标签
export function mailLabel(data) {
    return request({
        url:'/api/mailLabel',
        data,
        method:'post'
    });
}


// 发邮件
export function sendMsg(data) {
    return request({
        url:'/api/mail/sendMsg',
        data,
        method:'post'
    })
}
// 发邮件初始化
export function sendMsgInit(params) {
    return request({
        url:'/api/mail/sendMsgInit',
        params,
        method:'get'
    });
}

// 删除标签
export function removeTag(id) {
    return request({
        url:`/api/mailLabel/${id}`,
        method:'delete',
    });
}

// 查询标签
export function tagListPage(params) {
    return request({
        url:'/api/MailTemplate/listPage',
        params,
        method:'get'
    })
}

export function mailLabelRelation(data){
    return request({
        url:'/api/mailLabelRelation',
        method:'post',
        data
    })
}
export function getOneStationMail(params){
    return request({
        url:'/api/mail/getOneStationMail',
        method:'get',
        params
    })
}
export function replied(data){
    return request({
        url:'/api/mail/sign',
        method:'put',
        data
    })
}

export function setAllIsRead(data) {
    return request({
        url:'/api/mail/setAllIsRead',
        method:'put',
        data
    });
}

// 乐天设为已读
export function isReadRakuten(data) {
    return request({
        url:'/api/MailRakuten/setIsRead',
        method:'patch',
        data
    });
}
 
export function setCompleted(data) {
    return request({
        url:'/api/MailRakuten/setCompleted',
        method:'patch',
        data
    })
}

//发送乐天邮件
export function RakuTenMailRply(data) {
    return request({
        url:'/api/MailRakuten/reply',
        method:'post',
        data
    })
}

// 存草稿
export function saveDrafts(data) {
    return request({
        url:'/api/mail/saveDrafts',
        method:'put',
        data
    });
}

export function delDraft(id) {
    return request({
        url:`/api/mail/${id}`,
        method:'delete',
    });
}

export function matchProductInfo(params) {
    return request({
        url:'/api/MailTemplate/matchProductInfo',
        params,
        method:'get'
    });
}

export function getFile(id) {
    return request({
        url:`/api/file/upload/getFile/${id}`,
        method:'get',
        responseType: "blob"
    })
}





// 非接口   时间格式化
export function timeStam(mss) {
      var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = (mss % (1000 * 60)) / 1000;
      hours = hours < 10 ? (hours) : hours;
      minutes = minutes < 10 ? (minutes) : minutes;
      seconds = seconds < 10 && seconds >= 1 ? (seconds) : seconds;
      return  hours + "h" + minutes + "min"
  }
